import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  CustomerWebColors,
  CustomerWebText,
  googleApplicationMapService,
  osmApplicationMapService,
} from "@app/types/setting";
import { settingFallback } from "@app/utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { SettingAPI } from "@app/services/api";
import { fetchTrackOrder } from "./sharedThunks";
import { Languages } from "@app/types/multilingual";


type GetInitialSettingInput = {
  restUrl ?: string;
}

export const fetchInitialSetting = createAsyncThunk(
  "setting/fetchInitialSetting",
  async (input: GetInitialSettingInput) => {
    const data = await new SettingAPI().getInitialSetting(input);
    return data.data;
  }
);



export interface SettingState {
  loading: "idle" | "loading" | "succeeded" | "failed"
  error: any,
  map: googleApplicationMapService | osmApplicationMapService | null;
  restServerUrl: string;
  socketServerUrl: string;
  trackDriverTimer: number;
  logoTypeUrl: string;
  favIconUrl: string;
  colors: CustomerWebColors;
  customerWebText: CustomerWebText;
  language: Languages,
}

const initialState: SettingState = {
  loading: 'idle',
  error: null,
  map: null,
  restServerUrl: settingFallback.restServerUrl,
  socketServerUrl: settingFallback.socketServerUrl,
  trackDriverTimer: settingFallback.trackDriverTimer,
  logoTypeUrl: settingFallback.logoTypeUrl,
  favIconUrl: settingFallback.favIconUrl,
  colors: settingFallback.colors,
  customerWebText: settingFallback.customerWebText,
  language: 'en'
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setMap: (state, action: PayloadAction<SettingState["map"]>) => {
      state.map = action.payload;
    },
    setRestServerUrl: (
      state,
      action: PayloadAction<SettingState["restServerUrl"]>
    ) => {
      state.restServerUrl = action.payload;
    },
    setSocketServerUrl: (
      state,
      action: PayloadAction<SettingState["socketServerUrl"]>
    ) => {
      state.socketServerUrl = action.payload;
    },
    setSetting: (state, action: PayloadAction<SettingState>) => {
      state.map = action.payload.map;
      state.restServerUrl = action.payload.restServerUrl;
      state.socketServerUrl = action.payload.socketServerUrl;
      state.trackDriverTimer = action.payload.trackDriverTimer;
      state.logoTypeUrl = action.payload.logoTypeUrl;
      state.favIconUrl = action.payload.favIconUrl;
      state.colors = action.payload.colors;
      state.customerWebText = action.payload.customerWebText;
      state.language= action.payload.language
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialSetting.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchInitialSetting.fulfilled, (state, action) => {
        state.loading = "succeeded";

        // state.map = action.payload.map;
        state.restServerUrl = action.payload.restServerUrl;
        state.socketServerUrl = action.payload.socketServerUrl;
        // state.trackDriverTimer = action.payload.trackDriverTimer;
        state.logoTypeUrl = action.payload.logoTypeUrl;
        state.favIconUrl = action.payload.favIconUrl;
        state.colors = action.payload.colors;
        state.customerWebText = action.payload.customerWebText;
        state.language = action.payload.defaultLanguage as Languages;
  
      })
      .addCase(fetchInitialSetting.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      })

      // fetch initial setting from track api directly
      .addCase(fetchTrackOrder.fulfilled, (state, action) => {

        state.map = action.payload.setting.applicationMap;
        state.trackDriverTimer = action.payload.setting.customerTimer.trackDriverTimer;
        state.language = action.payload.language;

        if(state.loading != 'succeeded'){
          state.restServerUrl = action.payload.setting.restServerUrl;
          state.socketServerUrl = action.payload.setting.socketServerUrl;
          state.logoTypeUrl = action.payload.setting.logoTypeUrl;
          state.favIconUrl = action.payload.setting.favIconUrl;
          state.colors = action.payload.setting.customerWebConfig.colors;
          state.customerWebText = action.payload.setting.customerWebConfig.customerWebText;

        }
        
        state.loading = "succeeded";
  

      });
  },
});

// Action creators are generated for each case reducer function
export const { setMap, setRestServerUrl, setSocketServerUrl, setSetting } =
  settingSlice.actions;

export default settingSlice.reducer;
