import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  OrderType,
  OrderStatus,
  DriverType,
} from "@app/types/orders";
import { fetchTrackOrder } from "./sharedThunks";
import { ApiError } from "@app/types/api";

export interface OrderState {
  orderFetchStatus: "idle" | "loading" | "succeeded" | "failed";
  error: ApiError | null;
  orderId: string;
  code: string;
  trackOrderCode: string;
  type?: typeof OrderType[number];
  status: OrderStatus;
  statusDescription: string;
  driver?: DriverType;
  events: {
    title: string;
    description: string;
    createdAt: number;
  }[],
  isEventNeedUpdate: boolean;
  realETA: number;
}

const initialState: OrderState = {
  orderFetchStatus: 'idle',
  error: null,
  orderId: "",
  code: "",
  trackOrderCode: "",
  type: undefined,
  status: "Assigned",
  statusDescription: "",
  events: [],
  isEventNeedUpdate: false,
  realETA: 0,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    clearOrder: (state) => {
      state.orderFetchStatus = initialState.orderFetchStatus;
      state.error = initialState.error;
      state.orderId = initialState.orderId;
      state.code = initialState.code;
      state.trackOrderCode = initialState.trackOrderCode;
      state.type = initialState.type;
      state.status = initialState.status;
      state.statusDescription = initialState.statusDescription;
      state.events = initialState.events;
      state.isEventNeedUpdate = initialState.isEventNeedUpdate;
      state.realETA = initialState.realETA;

    },
    setOrderId: (state, action: PayloadAction<OrderState["orderId"]>) => {
      state.orderId = action.payload;
    },
    setOrderCode: (state, action: PayloadAction<OrderState["code"]>) => {
      state.code = action.payload;
    },
    setOrderType: (state, action: PayloadAction<OrderState["type"]>) => {
      state.type = action.payload;
    },
    setOrderStatus: (
      state,
      action: PayloadAction<Pick<OrderState, "status" | "statusDescription">>
    ) => {
      if((state.status != action.payload.status) && state.type !== 'Ondemand' ) state.isEventNeedUpdate = true;
      state.status = action.payload.status;
      state.statusDescription = action.payload.statusDescription;

    },
    setOrderDriver: (state, action: PayloadAction<DriverType>) => {
      if (!state.driver) state.driver = Object();
      if (state.driver) {
        state.driver.avatarPhoto = action.payload.avatarPhoto;
        state.driver.name = action.payload.name;
        state.driver.phone = action.payload.phone;
        state.driver.rate = action.payload.rate;
        state.driver.vehicle = action.payload.vehicle;
        state.driver.vehicle.plate = action.payload.vehicle.plate;
        state.driver.vehicle.model = action.payload.vehicle.model;
        state.driver.vehicle.color = action.payload.vehicle.color;
      }
    },
    setOrderEvents: (state, action: PayloadAction<OrderState["events"]>) => {
      state.events = action.payload;
    },
    addOrderEvent: (
      state,
      action: PayloadAction<OrderState["events"][number]>
    ) => {
      state.events.push(action.payload);
    },
    setEventNeedUpdate: (state, action: PayloadAction<OrderState["isEventNeedUpdate"]>) => {
      state.isEventNeedUpdate = action.payload;
    },
    setRealETA: (state, action: PayloadAction<OrderState["realETA"]>) => {
      state.realETA = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrackOrder.pending, (state) => {
        state.orderFetchStatus = "loading";
      })
      .addCase(fetchTrackOrder.fulfilled, (state, action) => {
        state.orderFetchStatus = "succeeded";
        let order = action.payload.order;
        
        state.orderId = order.id;
        state.code = order.code;
        state.trackOrderCode = order.trackOrderCode;
        state.type = order.type;

        if((state.status != order.status) && state.type !== 'Ondemand' ) state.isEventNeedUpdate = true;
        state.status = order.status;
        state.statusDescription = order.statusDescription[action.payload.language];

        if(order.driver){

          if (!state.driver) state.driver = Object();
          if (state.driver) {
            state.driver.avatarPhoto = order.driver.avatarPhoto;
            state.driver.name = order.driver.name;
            state.driver.phone = order.driver.phone;
            state.driver.rate = order.driver.rate;
            state.driver.vehicle = order.driver.vehicle;
          }

        }


        if (
          order.type !== "Ondemand" &&
          action.payload.events &&
          action.payload.events.length > 0
        ) {
          const events = action.payload.events.map((event) => ({
            title: event.title[action.payload.language],
            description: event.description[action.payload.language],
            createdAt: event.createdAt,
          }));


          state.events = events;
        }

        state.realETA = order.realETA;

  

      })
      .addCase(fetchTrackOrder.rejected, (state, action) => {
        state.orderFetchStatus = "failed";
        state.error = action.payload as ApiError;

      });
  },
});

// Action creators are generated for each case reducer function
export const {
  clearOrder,
  setOrderType,
  setOrderStatus,
  setOrderDriver,
  setOrderId,
  setOrderCode,
  setOrderEvents,
  addOrderEvent,
  setEventNeedUpdate,
  setRealETA
} = orderSlice.actions;

export default orderSlice.reducer;
