import {
  WebAppInitialSetting
} from "@app/types/api";
import Axios from "axios";


type GetInitialSettingInput = {
  restUrl ?: string;
}

interface SettingAPIs {
  getInitialSetting(data: GetInitialSettingInput): Promise<WebAppInitialSetting>;
}

export class SettingAPI implements SettingAPIs {
  
  public async getInitialSetting({restUrl}: GetInitialSettingInput): Promise<WebAppInitialSetting> {
    
    const response = await Axios.request<WebAppInitialSetting>({
      method: "POST",
      url: `${restUrl ? restUrl : ''}/api/v1/customer/application/web/initial-setting`,
      headers: {},
    });

    return response.data;

  }


}

