import * as React from "react"
import { SVGProps } from "react"
interface SVGRProps {
  iconLightStart?: string
  iconDarkStart?: string
}
export const BarCodeSVGComponent = ({
  iconLightStart,
  iconDarkStart,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (

  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.07444 12.7112C1.66966 12.7112 1.33398 12.3755 1.33398 11.9707V4.07249C1.33398 3.66771 1.66966 3.33203 2.07444 3.33203C2.47923 3.33203 2.8149 3.66771 2.8149 4.07249V11.9707C2.8149 12.3755 2.47923 12.7112 2.07444 12.7112Z"
      fill={iconDarkStart ? iconDarkStart : "#004CC4" }
    />
    <path
      d="M5.03733 8.76207C4.63255 8.76207 4.29688 8.42639 4.29688 8.02161V4.07249C4.29688 3.66771 4.63255 3.33203 5.03733 3.33203C5.44212 3.33203 5.77779 3.66771 5.77779 4.07249V8.02161C5.77779 8.42639 5.44212 8.76207 5.03733 8.76207Z"
      fill={iconDarkStart ? iconDarkStart : "#004CC4" }
    />
    <path
      d="M5.03733 12.7104C4.63255 12.7104 4.29688 12.3747 4.29688 11.9699V10.9826C4.29688 10.5779 4.63255 10.2422 5.03733 10.2422C5.44212 10.2422 5.77779 10.5779 5.77779 10.9826V11.9699C5.77779 12.3747 5.44212 12.7104 5.03733 12.7104Z"
      fill={iconLightStart ?  iconLightStart : "#BDE9FF"}
    />
    <path
      d="M10.9592 5.80023C10.5544 5.80023 10.2188 5.46455 10.2188 5.05977V4.07249C10.2188 3.66771 10.5544 3.33203 10.9592 3.33203C11.364 3.33203 11.6997 3.66771 11.6997 4.07249V5.05977C11.6997 5.46455 11.364 5.80023 10.9592 5.80023Z"
      fill={iconLightStart ?  iconLightStart : "#BDE9FF"}
    />
    <path
      d="M7.99632 12.7112C7.59153 12.7112 7.25586 12.3755 7.25586 11.9707V4.07249C7.25586 3.66771 7.59153 3.33203 7.99632 3.33203C8.4011 3.33203 8.73678 3.66771 8.73678 4.07249V11.9707C8.73678 12.3755 8.4011 12.7112 7.99632 12.7112Z"
      fill={iconDarkStart ? iconDarkStart : "#004CC4" }
    />
    <path
      d="M10.9592 12.7113C10.5544 12.7113 10.2188 12.3756 10.2188 11.9708V8.02171C10.2188 7.61692 10.5544 7.28125 10.9592 7.28125C11.364 7.28125 11.6997 7.61692 11.6997 8.02171V11.9708C11.6997 12.3756 11.364 12.7113 10.9592 12.7113Z"
      fill={iconDarkStart ? iconDarkStart : "#004CC4" }
    />
    <path
      d="M13.928 12.7112C13.5232 12.7112 13.1875 12.3755 13.1875 11.9707V4.07249C13.1875 3.66771 13.5232 3.33203 13.928 3.33203C14.3327 3.33203 14.6684 3.66771 14.6684 4.07249V11.9707C14.6684 12.3755 14.3327 12.7112 13.928 12.7112Z"
      fill={iconDarkStart ? iconDarkStart : "#004CC4" }
    />
  </svg>

)
