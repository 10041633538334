import * as React from "react"
import { SVGProps } from "react"
interface SVGRProps {
  iconLightStart?: string
  iconDarkStart?: string
}
export const SearchSVGComponent = ({
  iconLightStart,
  iconDarkStart,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (

  <svg
    width={52}
    height={52}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_19326_461323)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8507 -0.0585938C40.5764 -0.0585938 50.916 10.281 50.916 23.034C50.916 35.7871 40.5764 46.1267 27.8507 46.1267C15.0977 46.1267 4.75806 35.7871 4.75806 23.034C4.75806 10.281 15.0977 -0.0585938 27.8507 -0.0585938Z"
        fill={iconLightStart ?  iconLightStart : "#BDE9FF"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.804327 47.3776L6.2429 41.95C7.31525 40.8804 9.05406 40.8803 10.1209 41.9554C11.1933 43.0278 11.1905 44.7666 10.1182 45.8362L4.67687 51.2611C4.14207 51.7931 3.43996 52.0619 2.7406 52.0619C2.03849 52.0619 1.33365 51.7931 0.79884 51.2556C-0.270773 50.1832 -0.268028 48.4472 0.804327 47.3776Z"
        fill={iconDarkStart ? iconDarkStart : "#004CC4" }
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.584 26.9573C16.584 28.6029 17.4616 30.0839 18.8329 30.8792L25.6894 34.856C26.3202 35.1851 27.0059 35.3771 27.6915 35.4045C27.7738 35.4045 27.8561 35.4594 27.9383 35.4594C28.048 35.4594 28.1303 35.4045 28.2126 35.4045C28.9257 35.3771 29.5839 35.1851 30.2147 34.856L37.0713 30.8792C38.47 30.0839 39.3477 28.6029 39.3477 26.9573V19.0587C39.3477 18.2359 39.1282 17.4405 38.7169 16.7823C38.6894 16.7823 38.6894 16.7823 38.6894 16.7549L38.6072 16.6726C38.2506 16.0418 37.7021 15.5207 37.0439 15.1367L30.2147 11.1874C28.816 10.3646 27.0881 10.3646 25.6894 11.1874L18.8329 15.1367C17.4616 15.9321 16.584 17.4405 16.584 19.0587V26.9573ZM35.426 28.0269C35.8099 27.8075 36.0568 27.3962 36.0568 26.9573V20.1831L29.5842 23.9131V31.4003L35.426 28.0269ZM27.9658 13.8743C27.7464 13.8743 27.5544 13.9292 27.335 14.0389L21.5755 17.3574L27.9658 21.0599L34.356 17.3849L28.5417 14.0389C28.3772 13.9292 28.1577 13.8743 27.9658 13.8743ZM20.4787 28.0267L26.2931 31.4001V23.9128L19.8754 20.1828V26.9571C19.8754 27.3959 20.1222 27.8073 20.4787 28.0267Z"
        fill={iconDarkStart ? iconDarkStart : "#004CC4" }
      />
    </g>
    <defs>
      <clipPath id="clip0_19326_461323">
        <rect width={52} height={52} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
