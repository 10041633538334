import { StyledParagraph } from "./detailsBtn.styles";
import { useNavigate } from "react-router-dom";
import { t } from '@app/utils/translation'
import { useSelector } from "react-redux";
import { RootState } from "@app/store";

type DetailsBtnProps = {};

export const DetailsButton = ({  }: DetailsBtnProps) => {

  let navigate = useNavigate();
  const language  = useSelector((state: RootState) => state.setting.language );
  const secondaryColor  = useSelector((state: RootState) => state.setting.colors.secondaryColor );
  const trackOrderCode  = useSelector((state: RootState) => state.order.trackOrderCode );


  async function goToDetails() {
    navigate(`/details/${trackOrderCode}`);
  }

  return (
    <StyledParagraph onClick={goToDetails} color={secondaryColor} >
      {t('OrderDetails',language)}
    </StyledParagraph>
  );
};
