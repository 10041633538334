import {LoaderSpan} from './loading.styles';

export const ProgressIndicator = () => {

  return (
    <LoaderSpan></LoaderSpan>    
  );
};


