import { useEffect, useState } from "react";
import { SettingAPI } from "@app/services/api";
import { MemoHeader } from "@app/components/Header";
import { MemoSearchBox } from "@app/layout/search-box";
import { useDispatch, useSelector } from "react-redux";
import { environment } from "@app/config/environment";
import { fetchInitialSetting } from "@app/store/setting.slice";
import { AppDispatch, RootState } from "@app/store";
import { Loader } from "@app/components/loading";

export const Search = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: RootState) => state.setting.loading);

  useEffect(() => {
    console.log("in search use effect");

    if(loading != 'succeeded'){
      let restUrl = undefined;
      if (environment.isDevelopment) restUrl = environment.localRestUrl;
  
      dispatch(fetchInitialSetting({ restUrl }));
    }
  }, []);

  if (loading == "loading") return <Loader />;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <MemoHeader />
      <MemoSearchBox />
    </div>
  );
};

export default Search;
