import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { fetchTrackOrder } from "./sharedThunks";

export interface ServiceState {
  service?: {
    title: string;
    icon: string;
    options: {
      title: string;
      type: "list" | "boolean";
      dataTitle: string | null;
    }[];
  };
  vehicle?: {
    title: string;
    icon: string;
    options: {
      title: string;
      type: "list" | "boolean";
      dataTitle: string | null;
    }[];
  };
}

const initialState: ServiceState = {};

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setService: (state, action: PayloadAction<ServiceState["service"]>) => {
      state.service = action.payload;
    },
    setVehicle: (state, action: PayloadAction<ServiceState["vehicle"]>) => {
      state.vehicle = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTrackOrder.fulfilled, (state, action) => {
    
      const order = action.payload.order;

      if (order.service) {
        let options = [];
        options = order.service.options.map((opt) => ({
          title: opt.title,
          type: opt.type,
          dataTitle: opt.dataTitle,
        }));

        state.service = {
          title: order.service.title[action.payload.language],
          icon: order.service.icon,
          options: options,
        }
        
      }

      if (order.vehicleType) {
        let options = [];
        options = order.vehicleType.options.map((opt: any) => ({
          title: opt.title[action.payload.language],
          type: opt.type,
          dataTitle: opt.dataTitle,
        }));

        state.vehicle = {
          title: order.vehicleType.title[action.payload.language],
          icon: order.vehicleType.icon,
          options: options,
        }

      }


    });
  },
});

// Action creators are generated for each case reducer function
export const { setService, setVehicle } = serviceSlice.actions;

export default serviceSlice.reducer;
