import { AppDispatch, RootState } from "@app/store";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchSVGComponent } from "@app/components/icon/search";
import { BarCodeSVGComponent } from "@app/components/icon/barcode";
import { Input } from "@app/components/input";
import { ProgressIndicator } from "@app/components/Progress-indicator";
import { t } from "@app/utils/translation";

import {
  StyledSearchBoxWarper,
  StyledSearchBoxColumn,
  StyledBoldSpan,
  StyledLightSpan,
  StyledTracKBtn,
} from "./searchBox.styles";
import { fetchTrackOrder } from "@app/store/sharedThunks";
import { environment } from "@app/config/environment";
import { useNavigate } from "react-router";
import { clearOrder } from "@app/store/order.slice";

type SearchBoxProps = {};

export const SearchBox = ({}: SearchBoxProps) => {
  const colors = useSelector((state: RootState) => state.setting.colors);
  const orderType = useSelector((state: RootState) => state.order.type);
  const trackOrderCode = useSelector(
    (state: RootState) => state.order.trackOrderCode
  );
  const fetchOrderError = useSelector((state: RootState) => state.order.error);
  const fetchOrderStatus = useSelector(
    (state: RootState) => state.order.orderFetchStatus
  );
  const language = useSelector((state: RootState) => state.setting.language);

  const [orderCode, setOrderCode] = useState<string>("");
  const [isBtnDisable, setBtnDisability] = useState<boolean>(true);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  function inputOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    let value = e.target.value;
    value = value.trim();

    setOrderCode(value);
    if (value && value.length > 3) {
      setBtnDisability(false);
    } else {
      setBtnDisability(true);
    }
  }

  function btnOnClick(e: any) {
    if (!isBtnDisable) {
      console.log({ orderCode });

      let restUrl = undefined;
      if (environment.isDevelopment) restUrl = environment.localRestUrl;
      dispatch(clearOrder())
      dispatch(fetchTrackOrder({ code: orderCode, language: null, restUrl }));
    }
  }

  const onKeyPress = (e: any) => {
    if (e.key === "Enter" || e.which === 13) {
      e.preventDefault();
      btnOnClick(e);
    }
  };

  useEffect(() => {
    console.log("in Search Box use effect");
    console.log(orderType);

    if (orderType && orderType === "PickupDelivery") {
      console.log("in to navigate search");
      navigate(`/details/${trackOrderCode}`, { replace: true });
    } else if (
      orderType &&
      ["Ondemand", "Pickup", "Delivery"].includes(orderType)
    ) {
      navigate(`/${trackOrderCode}`, { replace: true });
    }
  }, [orderType]);

  return (
    <StyledSearchBoxWarper>
      <StyledSearchBoxColumn>
        <SearchSVGComponent
          iconLightStart={colors.iconLightStart}
          iconDarkStart={colors.iconDarkStart}
        />

        <StyledBoldSpan> {t("TrackYourOrder", language)} </StyledBoldSpan>
        <StyledLightSpan>{t("CheckTheStatus", language)}</StyledLightSpan>

        <Input
          icon={
            <BarCodeSVGComponent
              iconLightStart={colors.iconLightStart}
              iconDarkStart={colors.iconDarkStart}
            />
          }
          placeholder={t("TypeTrackNumber", language)}
          underInputText={
            (fetchOrderError && fetchOrderError.message) ||
            t("TypeNoSpace", language)
          }
          onChange={inputOnChange}
          onKeyDown={onKeyPress}
          isError={fetchOrderError ? true : false}
        />

        <StyledTracKBtn
          role="button"
          tabIndex={0}
          textColor={colors.secondaryTextColor}
          backGroundColor={colors.secondaryColor}
          isDisable={isBtnDisable}
          onClick={btnOnClick}
        >
          {fetchOrderStatus == "loading" ? (
            <ProgressIndicator />
          ) : (
            <span>{t("Track", language)}</span>
          )}
        </StyledTracKBtn>
      </StyledSearchBoxColumn>
    </StyledSearchBoxWarper>
  );
};

export const MemoSearchBox = memo(SearchBox);
