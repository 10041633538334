import { useState, useCallback, useEffect } from "react";
import {
  GoogleMap,
  GoogleMapProps,
  useJsApiLoader,
} from "@react-google-maps/api";
import { GooglePickupPin } from "./pickup-pin";
import { GoogleDropOffPin } from "./dropoff-pin";
import { GoogleDriverPin } from "./driver-pin";

const containerStyle = {
  width: "100vw",
  height: "calc(100vh - 190px)",
  marginTop: "50px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const mapStyle = [
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
      {
        saturation: -100,
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
      {
        saturation: -100,
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
      {
        saturation: -10,
      },
      {
        lightness: 30,
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
      {
        saturation: -60,
      },
      {
        lightness: 10,
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
      {
        saturation: -60,
      },
      {
        lightness: 60,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
      {
        saturation: -100,
      },
      {
        lightness: 60,
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
      {
        saturation: -100,
      },
      {
        lightness: 60,
      },
    ],
  },
];

type GoogleProps = {
  apiKey: string;
  markers?: {
    lat: number;
    lng: number;
    type: "Pickup" | "DropOff" | "Driver";
    avatarPhoto?: string;
  }[];
};

export const Google = ({ apiKey, markers }: GoogleProps) => {
  const [zoom, setZoom] = useState(16);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey,
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const fitBounds = () => {
    if (map && markers) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.map((item) => {
        bounds.extend({
          lat: item.lat,
          lng: item.lng,
        });
      });
      
      if(markers.length == 1){
        bounds.extend({
          lat: (markers[0].lat + 0.002),
          lng: (markers[0].lng + 0.002),
        });
        bounds.extend({
          lat: (markers[0].lat - 0.002),
          lng: (markers[0].lng - 0.002),
        });
      }
      map.fitBounds(bounds, 100 );
    }
  };
  useEffect(() => {
    fitBounds();
  }, [map, markers]);

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  const renderMap = () => {
    const options: GoogleMapProps["options"] = {
      mapTypeControl: false,
      streetViewControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
      },
      // fullscreenControl: false,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
      styles: mapStyle,
    };

    console.log("map render : ");

    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={options}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {markers &&
          markers.map((marker) => {
            if (marker.type === "Pickup") {
              return (
                <GooglePickupPin
                  key={`${marker.lat}${marker.lng}`}
                  position={{
                    lat: marker.lat,
                    lng: marker.lng,
                  }}
                />
              );
            } else if (marker.type === "DropOff") {
              return (
                <GoogleDropOffPin
                  key={`${marker.lat}${marker.lng}`}
                  position={{
                    lat: marker.lat,
                    lng: marker.lng,
                  }}
                />
              );
            } else if (marker.type === "Driver") {
              return (
                <GoogleDriverPin
                  key={`${marker.lat}${marker.lng}`}
                  position={{
                    lat: marker.lat,
                    lng: marker.lng,
                  }}
                />
              );
            }
          })}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <span>Loading</span>;
};
