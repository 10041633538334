import { useEffect, useState } from "react";
import { MemoMainBottom } from "@app/layout/main-bottom";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MemoMapSection } from "@app/layout/map-section";
import { MemoETA } from "@app/components/ETA";
import { MemoHeader } from "@app/components/Header";
import { Loader }  from "@app/components/loading";
import { AppDispatch, RootState } from "@app/store";
import { fetchTrackOrder } from "@app/store/sharedThunks";
import { environment } from "@app/config/environment";

export const Main = () => {
  let {trackCode} = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const language = searchParams.get("language")

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const trackOrderCode = useSelector((state: RootState) => state.order.trackOrderCode);
  const orderType = useSelector((state: RootState) => state.order.type);
  const loading = useSelector((state: RootState) => state.order.orderFetchStatus);
  const error = useSelector((state: RootState) => state.order.error);


  useEffect(() => {
    console.log('in main use effect');
    console.log(trackOrderCode);
    console.log(trackCode);
    console.log(trackOrderCode != trackCode);
    console.log(orderType);
    
    
    if(!trackOrderCode || trackOrderCode != trackCode){
      console.log('in if 1');
      let restUrl = undefined;
      if (environment.isDevelopment) restUrl = environment.localRestUrl;
    
      dispatch(fetchTrackOrder({trackCode: trackCode || '' , language , restUrl}))  
    }

    if(orderType && orderType === 'PickupDelivery'){
      console.log("in to navigate");
      navigate(`/details/${trackCode}`, { replace: true });
    }


    if(error && error.data){
      if(error.data.code == -400){
        navigate('/not-found')
      }
      if(error.data.code == -403){
        navigate('/expire')
      }
    }

  
  }, [trackOrderCode, trackCode  , orderType , error]);


  if (loading == 'loading') return <Loader/>;



  if (orderType === "PickupDelivery") return <span>Redirect...</span>;

  return (
    <>
      <MemoHeader/>
      <MemoMapSection />
      <MemoETA />
      <MemoMainBottom/>
    </>
  );
};

export default Main;
