import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CustomerType } from "@app/types/orders";
import { fetchTrackOrder } from "./sharedThunks";

export interface CustomerState {
  customerId: string;
  name: string,
  type: CustomerType,
  avatarPhoto?: string
  accessToken: string
}

const initialState: CustomerState = {
  customerId: '',
  name: '',
  type: 'individual',
  accessToken: ''
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomer: (state, action: PayloadAction<CustomerState>) => {
      state.customerId = action.payload.customerId;
      state.name = action.payload.name;
      state.type = action.payload.type;
      state.avatarPhoto = action.payload.avatarPhoto;
      state.accessToken = action.payload.accessToken;
    },
  },
  extraReducers: (builder) => {
      builder
        .addCase(fetchTrackOrder.fulfilled, (state, action) => {
          let order = action.payload.order;
          
          state.customerId = order.customerId;
          state.name = order.customer.name;
          state.type = order.customer.type;
          state.avatarPhoto = order.customer.avatarPhoto;
          state.accessToken = action.payload.accessToken;
        })
    },



});

// Action creators are generated for each case reducer function
export const { setCustomer } = customerSlice.actions;

export default customerSlice.reducer;
