import { ColorsFallback, device } from "@app/utils/constants";
import styled from "styled-components";

export const StyledSearchBoxWarper = styled.div`
  margin:  42px auto ;
`;

export const StyledSearchBoxColumn = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.laptop} {
    width: calc(100vw - 66vw);
  } 

`;

export const StyledBoldSpan = styled.span`
  font-family: SFProRounded;
  font-size: 24px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
  margin-top: 24px;

`;


export const StyledLightSpan = styled.span`
  font-family: SFProRounded;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  margin-top: 4px;
  margin-bottom: 24px ;

`;


interface ITracKBtn  {
  textColor?: string,
  backGroundColor?: string,
  isDisable?: boolean,
}

export const StyledTracKBtn = styled.div<ITracKBtn>`
    width: fit-content;
    color: ${props=> props.textColor || ColorsFallback.secondaryTextColor};
    text-align: center;
    font-family: SFProRounded;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    background:  #1080ff;
    background: ${props=> props.backGroundColor || ColorsFallback.secondaryColor};
    display: inline-flex;
    padding: 9px 30px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    user-select: none;
    height: 24px;
    cursor: pointer;
    max-height: 42px;

    ${(props)=>{
      if(props.isDisable){
        return `
            opacity: 0.4;
            cursor: not-allowed;
        `
      }
    }};
`;