import { createAsyncThunk } from "@reduxjs/toolkit";
import { OrderAPI } from "@app/services/api";
import { ApiError, OrderTrackResponse } from "@app/types/api";

type TrackOrderInput = {
  restUrl?: string;
  trackCode?: string;
  code?: string;
  language: string| null;
};


export interface OrderTrackResponse2 {
  
  language: string;
}

export const fetchTrackOrder = createAsyncThunk<
  OrderTrackResponse['data'], // Success return type
  TrackOrderInput, // Argument type (orderCode)
  { rejectValue: ApiError } // Rejected payload type
>(
  "shared/fetchTrackOrder",
  async (input: TrackOrderInput , { rejectWithValue  }) => {

    try {

      console.log('shared/fetchTrackOrder 222');
      const data = await new OrderAPI().trackOrder(input);
      console.log(data);
      
      return data.data;
      

    } catch (error) {
      console.log('in error');
      console.log(error);
      return rejectWithValue(error as ApiError);
    }

  }
);
