import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Point } from "@app/types/orders";
import { fetchTrackOrder } from "./sharedThunks";
import { OrderAPI } from "@app/services/api";
import { Languages } from "@app/types/multilingual";

type TrackDriverInput = {
  restUrl: string;
  accessToken: string;
  orderId: string;
  customerId: string;
  language: Languages;
};

export const fetchTrackDriver = createAsyncThunk(
  "orders/fetchTrackDriver",
  async (input: TrackDriverInput) => {
    const data = await new OrderAPI().trackDriver(input);
    return data.data;
  }
);



export interface MarkerState {
  pickup?: Point;
  dropoffs?: Point[];
  driver?: Point;
}

const initialState: MarkerState = {};

export const markerSlice = createSlice({
  name: "marker",
  initialState,
  reducers: {
    setPickup: (state, action: PayloadAction<MarkerState['pickup']>) => {
      state.pickup = action.payload
    },
    setDropOffs: (state, action: PayloadAction<MarkerState['dropoffs']>) => {
      state.dropoffs = action.payload
    },
    setDriver: (state, action: PayloadAction<MarkerState['driver']>) => {
      state.driver = action.payload
    },
  },
  extraReducers: (builder) => {
      builder.addCase(fetchTrackOrder.fulfilled, (state, action) => {
      
        const order = action.payload.order;
  
        if(order.pickup){
          state.pickup = {
            lat: order.pickup.coordinates[1],
            lng: order.pickup.coordinates[0],
            address: order.pickup.address,
          }
        }
    
        if(order.dropoffs){

          const dropoffs = order.dropoffs.map((d: any) => {
            return {
              lat: d.coordinates[1],
              lng: d.coordinates[0],
              address: d.address,
            };
          });

          state.dropoffs = dropoffs;

        }

        if (order.dropoff) {
          state.dropoffs = [{
              lat: order.dropoff.coordinates[1],
              lng: order.dropoff.coordinates[0],
              address: order.dropoff.address,
          }]
        }

        if (order.delivery) {
          state.dropoffs = [{
              lat: order.delivery.coordinates[1],
              lng: order.delivery.coordinates[0],
              address: order.delivery.address,
          }]
        }


  
      })

      builder.addCase(fetchTrackDriver.fulfilled, (state, action) => {
      
        state.driver = {
          lat: action.payload.location[1],
          lng: action.payload.location[0],
        };

      });
    },

});

// Action creators are generated for each case reducer function
export const { setPickup , setDropOffs ,  setDriver } = markerSlice.actions;

export default markerSlice.reducer;
