import {
  StyledSearchInputWarper,
  StyledSearchInput,
  StyledUnderInputSpan,
} from "./input.styles";
import { ReactElement } from "react";

type InputProps = {
  icon?: ReactElement,
  isError?: boolean,
  placeholder?: string,
  underInputText?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>

};

export const Input = (props: InputProps) => {


  return (
    <>
      <StyledSearchInputWarper isError={props.isError}>
        {props.icon}
        <StyledSearchInput autoComplete="on" onChange={props.onChange} placeholder={props.placeholder} onKeyDown={props.onKeyDown} />
      </StyledSearchInputWarper>
      <StyledUnderInputSpan isError={props.isError} >{props.underInputText}</StyledUnderInputSpan>
    </>

  );
};

