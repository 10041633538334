
export const localStorageConst = {
    accessToken : Symbol.for('accessToken').toString()
}

export const ColorsFallback = {
    loginBackgroundColorStart:"#1080FF",
    loginBackgroundColorEnd:"#58A7FF",
    LoginTextColor:"#FFFFFF",
    secondaryColor:"#1080FF",
    secondaryDarkColor:"#0055cb",
    secondaryLightColor:"#25B7FF",
    secondaryUltraLight:"#D9EAFF",
    secondaryTextColor:"#ffffff",
    secondaryonlight:"#1080FF",
    iconLightStart:"#B7D9FF",
    iconLightEnd:"#BDE9FF",
    iconDarkStart:"#004CC4",
    iconDarkEnd:"#0083E4"
 };

  
export const settingFallback = {
    restServerUrl: '',
    socketServerUrl: '',
    trackDriverTimer: 5000,
    logoTypeUrl: 'https://onro.io/assets/pics/logo.webp',
    favIconUrl: 'https://onro.io/assets/pics/icon/fav_icon.png',
    colors: ColorsFallback,
    customerWebText: {
        title: "---",
        loginFirstLine: "",
        loginFirstLinePartTwo : "",
        loginSecondLine: "",
    
    }
};
  

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
}

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`
};