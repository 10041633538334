import { ColorsFallback, device } from "@app/utils/constants";
import styled from "styled-components";


interface ISearchInputWarper  {
  isError?: boolean,
}

export const StyledSearchInputWarper = styled.div<ISearchInputWarper>`
  height: 42px;
  border-radius: 8px;
  /* border: 1px solid #f4f4f4; */
  border: 1px solid ${props => props.isError ? '#D01400':'#f4f4f4'} ;
  background: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  justify-content: start;
  width: 100%;
  padding: 0 8px;
  font-family: SFProRounded;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;



export const StyledSearchInput = styled.input`
  font-family: SFProRounded;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  height: 16px;
  color: #808080;
  outline: none;
  border: unset;
  flex: 1 1;
  width: inherit;
`;

interface IUnderInputSpan  {
  isError?: boolean,
}

export const StyledUnderInputSpan = styled.span<IUnderInputSpan>`
  font-family: SFProRounded;
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.isError ? '#D01400':'#808080'} ;
  margin-top: 4px;
  margin-bottom: 24px ;

`;