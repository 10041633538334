import {
  OrderTrackResponse,
  TrackDriverResponse,
  EstimatedArrivalResponse,
  GetEventsResponse,
} from "@app/types/api";
import { Languages } from "@app/types/multilingual";
import Axios from "axios";
// import { LocalData } from "@app/services/localStorage";
// import { localStorageConst } from "@app/utils/constants";

type TrackOrderInput = {
  restUrl?: string;
  trackCode?: string;
  code?: string;
  language: string| null;
};

type TrackDriverInput = {
  restUrl: string;
  accessToken: string;
  orderId: string;
  customerId: string;
  language: Languages;
};

type EstimatedArrivalInput = {
  restUrl: string;
  accessToken: string;
  orderId: string;
  customerId: string;
  language: Languages;
};

type GetEventsInput = {
  restUrl: string;
  accessToken: string;
  orderId: string;
  customerId: string;
  language: Languages;
};

interface OrderAPIs {
  trackOrder(data: TrackOrderInput): Promise<OrderTrackResponse>;
  trackDriver(data: TrackDriverInput): Promise<TrackDriverResponse>;
  estimatedArrival(data: EstimatedArrivalInput): Promise<EstimatedArrivalResponse>;
  getEvents(data: GetEventsInput): Promise<GetEventsResponse>;

}

export class OrderAPI implements OrderAPIs {
  
  // private storage = new LocalData();

  public async trackOrder(data: TrackOrderInput): Promise<OrderTrackResponse> {
    
    try {
        const searchParams = new URLSearchParams();
        if(data.code){
          searchParams.append("code", data.code);
        }
        if(data.trackCode){
          searchParams.append("trackCode", data.trackCode);
        }

        let url = `${ data.restUrl ? data.restUrl : "" }/api/v1/customer/order/track?` + searchParams.toString();

        const response = await Axios.get<OrderTrackResponse>(url, {params: {language: data.language}} );
        return response.data;
      
    } catch (error: any) {
      console.log(error);
      
      // Handle Axios errors
      if (error.response) {
        // Server responded with a status code outside 2xx
        return Promise.reject({
          message: error.response.data.message || "API Error",
          status: error.response.status,
          data: error.response.data, // Full error response
        });
      } else if (error.request) {
        // Request was made but no response received
        return Promise.reject({
          message: "No response from server",
          details: error.request,
        });
      } else {
        // Other errors (e.g., Axios config issues)
        return Promise.reject({
          message: "Request setup error",
          details: error.message,
        });
      }
    }

  }

  public async trackDriver(data : TrackDriverInput): Promise<TrackDriverResponse> {
    
    const response = await Axios.request<TrackDriverResponse>({
      method: "POST",
      url: `${data.restUrl}/api/v1/customer/order/track-driver`,
      headers: {
        "Accept-Language": data.language,
        Authorization: `Bearer ${data.accessToken}`,
      },
      data: {
        orderId: data.orderId,
        customerId: data.customerId,
      },
    });
    return response.data;
  }

  public async estimatedArrival(data : EstimatedArrivalInput): Promise<EstimatedArrivalResponse> {

    const response = await Axios.request<EstimatedArrivalResponse>({
      method: "POST",
      url: `${data.restUrl}/api/v1/customer/order/ondemand/estimated-arrival`,
      headers: {
        "Accept-Language": data.language,
        Authorization: `Bearer ${data.accessToken}`,
      },
      data: {
        orderId: data.orderId,
        customerId: data.customerId,
      },
    });
    return response.data;
  }

  public async getEvents(data: GetEventsInput): Promise<GetEventsResponse> {
    

    const response = await Axios.request<GetEventsResponse>({
      method: "GET",
      url: `${data.restUrl}/api/v1/customer/order/event`,
      headers: {
        "Accept-Language": data.language,
        Authorization: `Bearer ${data.accessToken}`,
      },
      params: {
        orderId: data.orderId,
        customerId: data.customerId,
      },
    });
    return response.data;

  }
}

