import { BrowserRouter, Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import Main from "@app/page/main";
import Search from "@app/page/search";
import { Loader } from "@app/components/loading";

const Details = lazy(() => import("@app/page/details"));
const NotFound = lazy(() => import("@app/page/notFound"));
const Expire = lazy(() => import("@app/page/expire"));

export const RouteManager = () => {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Search/>} />
      <Route path="/:trackCode" element={<Main />} />
        <Route
          path="/details/:trackCode"
          element={
            <Suspense fallback={<Loader/>}>
              <Details />
            </Suspense>
          }
        />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/expire" element={<Expire />} />

        <Route
          path="*"
          element={
            <Suspense fallback={<Loader/>}>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
